import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Container } from 'react-bootstrap'
import { getContributions } from '../github_stats/contributions'
import { SummaryData, ChartData } from './types'
import GithubStats from './GithubStats'
import GithubChart from './GithubChart'

const GitHubActivity = () => {
  const [contributionSummary, setContributionSummary] = useState<SummaryData>()
  const [monthlyData, setMonthlyData] = useState<ChartData[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const githubToken: string = process.env.REACT_APP_GITHUB_TOKEN as string

  useEffect(() => {
    setIsLoading(true)
    getContributions('ardikapras', githubToken)
      .then((value) => {
        setContributionSummary({
          totalContributions: value.totalContributions ?? 0,
          totalCommitContributions: value.totalCommitContributions,
          totalPullRequestContributions: value.totalPullRequestContributions,
          totalPullRequestReviewContributions:
            value.totalPullRequestReviewContributions,
          totalRepositoriesWithContributedCommits:
            value.totalRepositoriesWithContributedCommits,
        })

        const contributionsByMonth = new Map<
          string,
          {
            commits: number
            month: string
            pullRequests: number
            reviews: number
          }
        >()

        for (const week of value.contributions) {
          for (const day of week) {
            const date = new Date(day.date)
            const monthYear = `${date.toLocaleString('default', {
              month: 'short',
            })} ${date.getFullYear()}`

            if (!contributionsByMonth.has(monthYear)) {
              contributionsByMonth.set(monthYear, {
                month: monthYear,
                commits: 0,
                pullRequests: 0,
                reviews: 0,
              })
            }

            const monthData = contributionsByMonth.get(monthYear)!
            monthData.commits += day.contributionCount
          }
        }

        const chartData = Array.from(contributionsByMonth.values()).sort(
          (a, b) => {
            const dateA = new Date(a.month)
            const dateB = new Date(b.month)
            return dateA.getTime() - dateB.getTime()
          },
        )

        const last12Months = chartData.slice(-12)
        setMonthlyData(last12Months)
        setIsLoading(false)
      })
      .catch((reason) => {
        console.error('Error fetching contributions:', reason)
        setIsLoading(false)
      })
  }, [githubToken])

  return (
    <Card className="border-0 shadow-sm">
      <CardHeader className="bg-light border-0">
        <h5 className="mb-0">
          <span className="text-success">GitHub</span> Activity
        </h5>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <div className="text-center py-5">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-2 text-muted">Loading GitHub activity...</p>
          </div>
        ) : (
          <Container fluid className="p-0">
            <GithubStats contributionSummary={contributionSummary} />
            <GithubChart monthlyData={monthlyData} />
          </Container>
        )}
      </CardBody>
    </Card>
  )
}

export default GitHubActivity
