import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import styles from '../styles.module.css'
import { TechJourney } from '../techjourney'
import { jobExperiences, ResumeSummary } from '../resume'

const Resume = () => {
  const [selectedHashtags, setSelectedHashtags] = useState<string[]>([])

  useEffect(() => {
    AOS.init()
  }, [])

  const filteredJobExperiences =
    selectedHashtags.length > 0
      ? jobExperiences.filter((experience) =>
          selectedHashtags.every((hashtag) =>
            experience.hashtags.includes(hashtag),
          ),
        )
      : jobExperiences

  const toggleHashtag = (hashtag: string) => {
    if (selectedHashtags.includes(hashtag)) {
      setSelectedHashtags(selectedHashtags.filter((h) => h !== hashtag))
    } else {
      setSelectedHashtags([...selectedHashtags, hashtag])
    }
  }

  const clearSelection = () => {
    setSelectedHashtags([])
  }

  return (
    <Container data-aos="fade-up" className="d-block">
      <div className="section-short-summary text-center align-middle mb-4">
        <h2 className="text-uppercase fw-bold pb-3">Resume</h2>
        <div className={`${styles['border-bottom']}`} />
        <p className="pt-4 px-md-5">
          Experienced Software Engineer with a diverse background and proven
          track record of client interaction, requirement gathering, system
          impact analysis, project planning, and production support.
        </p>
      </div>

      <Row className="g-4">
        <Col lg={4} md={12}>
          <ResumeSummary
            selectedHashtags={selectedHashtags}
            toggleHashtag={toggleHashtag}
            clearSelection={clearSelection}
          />
        </Col>
        <Col lg={8} md={12}>
          <TechJourney jobExperiences={filteredJobExperiences} />
        </Col>
      </Row>
    </Container>
  )
}

export default Resume
