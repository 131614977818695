import React, { useState } from 'react'
import { Card, Badge, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { JobExperience } from './type'
import { differenceInMonths, differenceInYears, parse } from 'date-fns'
import styles from './ExperienceCard.module.css'
import { CustomBadge } from '../common'
import ExperienceModal from './ExperienceModal'

const getCompanyLogo = (companyName: string): string => {
  const companyLogos: Record<string, string> = {
    'PT Dkatalis Digital Lab, Indonesia': '/logos/dkatalis.png',
    'Rakuten Rebates, Japan': '/logos/rakuten.png',
    'Paymentology, Remote': '/logos/paymentology.jpeg',
    'Tokopedia, Indonesia': '/logos/dhanapala.png',
    'Hitachi eBworx Sdn Bhd, Malaysia': '/logos/hitachi-ebworx.png',
    'PT. ABB Sakti Industri, Indonesia': '/logos/abb.png',
  }

  return companyLogos[companyName] || '/logos/default-company-logo.png'
}

function calculateDuration(dateRange: string) {
  const currentDate = new Date()
  const [startMonthYear, endMonthYear] = dateRange.split(' - ')

  const startDate = parse(startMonthYear, 'MMM yyyy', new Date())
  const endDate =
    endMonthYear === 'now' || endMonthYear === 'present'
      ? currentDate
      : parse(endMonthYear, 'MMM yyyy', new Date())

  const yearsDiff = differenceInYears(endDate, startDate)
  const monthsDiff = differenceInMonths(endDate, startDate) % 12

  const formattedYears = yearsDiff > 0 ? `${yearsDiff}y` : ''
  const formattedMonths = monthsDiff > 0 ? `${monthsDiff}m` : ''

  return `${formattedYears} ${formattedMonths}`.trim()
}

interface ExperienceCardProps {
  experience: JobExperience
}

const ExperienceCard: React.FC<ExperienceCardProps> = ({ experience }) => {
  const [showModal, setShowModal] = useState(false)
  const [logoError, setLogoError] = useState(false)

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const handleLogoError = () => setLogoError(true)

  const logoUrl = getCompanyLogo(experience.company)

  return (
    <>
      <Card
        className={styles.experienceCard}
        onClick={handleShow}
        style={{ cursor: 'pointer', height: '100%' }}
      >
        <Card.Body className="p-3">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <h5 className={styles.cardTitle}>{experience.title}</h5>
            <Badge className={`${styles.durationBadge} bg-success`} pill>
              {calculateDuration(experience.date)}
            </Badge>
          </div>

          <div className="mb-2 d-flex align-items-center">
            {!logoError ? (
              <Image
                src={logoUrl}
                className={styles.companyLogo}
                onError={handleLogoError}
                alt={experience.company}
              />
            ) : (
              <FontAwesomeIcon icon={faBuilding} className={styles.iconMuted} />
            )}
            <span className="ms-2 text-muted small">{experience.company}</span>
          </div>

          <div className="text-secondary small mb-3">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className={styles.iconMuted}
            />
            <span className="ms-2">{experience.date}</span>
          </div>

          <div className={styles.tagsContainer}>
            {experience.hashtags.slice(0, 3).map((tag, idx) => (
              <CustomBadge key={idx} text={tag} variant="tech" />
            ))}
            {experience.hashtags.length > 3 && (
              <Badge className={`${styles.moreBadge} bg-black`} pill>
                +{experience.hashtags.length - 3} more
              </Badge>
            )}
          </div>
        </Card.Body>
        <div className={styles.cardFooter}>
          <small>Click to view details</small>
        </div>
      </Card>

      <ExperienceModal
        experience={experience}
        showModal={showModal}
        handleClose={handleClose}
        logoUrl={logoUrl}
        logoError={logoError}
      />
    </>
  )
}

export default ExperienceCard
