export type JobExperience = {
  id: number
  title: string
  date: string
  company: string
  description: string
  highlights: string[]
  hashtags: string[]
}

export const jobExperiences: JobExperience[] = [
  {
    id: 0,
    title: 'Senior Engineer',
    date: 'Jan 2025 - now',
    company: 'PT Dkatalis Digital Lab, Indonesia',
    description:
      'PT Dkatalis Digital Lab is a digital transformation company based in Indonesia that specializes in building digital banking and fintech solutions.',
    highlights: [
      'Engineered loan origination system improvements using Kotlin and Spring Boot, enhancing reliability',
      'Developed responsive UI components with React TypeScript and implemented comprehensive test coverage',
    ],
    hashtags: [
      'kotlin',
      'spring-boot',
      'mongodb',
      'react',
      'typescript',
      'flutter',
      'jest',
    ],
  },
  {
    id: 1,
    title: 'Web Application Engineer',
    date: 'Sep 2024 - Dec 2024',
    company: 'Rakuten Rebates, Japan',
    description:
      'Rakuten Rebates (formerly Ebates) is a leading cashback shopping platform that partners with thousands of retailers to offer customers money back on their purchases.',
    highlights: [
      'Led the Web Application team developing backend APIs serving both web and mobile platforms',
      'Maintained and enhanced the Rebates website based on evolving user requirements',
      "Successfully migrated the batch processing system from Elastic Search to Rakuten's internal platform",
      'Optimized XML processing for large files (5-7GB), reducing memory consumption from 20GB to under 1GB',
      'Implemented performance improvements across the web platform, enhancing overall user experience',
    ],
    hashtags: [
      'java',
      'spring-boot',
      'aws',
      'postgresql',
      'react',
      'typescript',
      'elasticsearch',
    ],
  },
  {
    id: 2,
    title: 'Software Developer',
    date: 'Jun 2022 - Aug 2024',
    company: 'Paymentology, Remote',
    description:
      'Paymentology is a leading financial technology company that focuses on providing cutting-edge solutions for payment processing and card management.',
    highlights: [
      'Designed and implemented Tokenization Lifecycle Management.',
      'Designed and implemented Single Sign-On with Azure AD.',
      'Designed and implemented a service that calculates metrics from AWS Athena Log.',
      'Handling 2 big system, with different client and different tech stack',
    ],
    hashtags: [
      'java',
      'spring-boot',
      'coldfusion',
      'postgresql',
      'sql-server',
      'react',
      'typescript',
    ],
  },
  {
    id: 3,
    title: 'Senior Software Engineer',
    date: 'Jan 2021 - Jun 2022',
    company: 'Tokopedia, Indonesia',
    description:
      'Dhanapala, a subsidiary of Tokopedia, is a dynamic team dedicated to advancing financial solutions and services for merchants on the Tokopedia platform.',
    highlights: [
      'Rebuild architecture from monolith become microservice.',
      'Enabling the loan matching process for multi-lenders.',
    ],
    hashtags: [
      'golang',
      'microservices',
      'loan-origination-system',
      'redis',
      'message-queue',
      'postgresql',
      'tech-lead',
    ],
  },
  {
    id: 4,
    title: 'Technical Lead',
    date: 'Aug 2018 - Jan 2021',
    company: 'Hitachi eBworx Sdn Bhd, Malaysia',
    description:
      'Hitachi Ebworx is a global software solutions and services provider specializing in financial technology. They deliver a wide range of software solutions for banks and financial institutions.',
    highlights: [
      'Led performance testing on RHB Bank project.',
      'Led a technical team on the RHB Bank project to implement Auto Financing.',
    ],
    hashtags: ['java', 'oracle-db', 'banking', 'oracle-weblogic', 'tech-lead'],
  },
  {
    id: 5,
    title: 'Senior Software Engineer',
    date: 'Aug 2017 - Aug 2018',
    company: 'PT. ABB Sakti Industri, Indonesia',
    description:
      'As part of the Research and Development team, I contributed to the development and maintenance of an internal Java framework for efficient software development.',
    highlights: [
      'Participate in migrating from a Flex-based framework to a modern Java-based framework.',
      'Migrating from a Flex-based automation testing to Java-based automation testing.',
    ],
    hashtags: ['java', 'selenium', 'postgresql', 'vaadin'],
  },
  {
    id: 6,
    title: 'Senior Application Engineer',
    date: 'Dec 2014 - Aug 2017',
    company: 'Hitachi eBworx Sdn Bhd, Malaysia',
    description:
      'Hitachi Ebworx is a global software solutions and services provider specializing in financial technology. They deliver a wide range of software solutions for banks and financial institutions.',
    highlights: [
      'Led a small team to perform Tech Refresh.',
      'Led a technical team on the AmBank project to implement Non-Mortgage.',
      'Do production support for the existing system.',
    ],
    hashtags: ['java', 'oracle-db', 'banking', 'oracle-weblogic'],
  },
]

export default jobExperiences
