import React from 'react'
import { SectionTitle } from '../common'
import SkillsFilter from './SkillsFilter'
import BackgroundInfo from './BackgroundInfo'

interface ResumeSummaryProps {
  selectedHashtags: string[]
  toggleHashtag: (hashtag: string) => void
  clearSelection: () => void
}

const ResumeSummary: React.FC<ResumeSummaryProps> = ({
  selectedHashtags,
  toggleHashtag,
  clearSelection,
}) => {
  return (
    <div className="pe-md-3">
      <SectionTitle title="Skills & Experience" />

      {/* Skills Filter Card */}
      <SkillsFilter
        selectedHashtags={selectedHashtags}
        toggleHashtag={toggleHashtag}
        clearSelection={clearSelection}
      />

      {/* Background Information Card */}
      <BackgroundInfo />
    </div>
  )
}

export default ResumeSummary
