import React from 'react'
import { Row, Col, Card, CardBody } from 'react-bootstrap'
import { SummaryData } from './types'

interface GithubStatsProps {
  contributionSummary: SummaryData | undefined
}

const GithubStats: React.FC<GithubStatsProps> = ({ contributionSummary }) => {
  if (!contributionSummary) return null

  const stats = [
    {
      title: 'Total Contributions',
      value: contributionSummary.totalContributions,
      color: '#34b7a7',
    },
    {
      title: 'Repositories Contributed',
      value: contributionSummary.totalRepositoriesWithContributedCommits,
      color: '#4169E1',
    },
    {
      title: 'Pull Requests',
      value: contributionSummary.totalPullRequestContributions,
      color: '#9370DB',
    },
    {
      title: 'Code Reviews',
      value: contributionSummary.totalPullRequestReviewContributions,
      color: '#6A5ACD',
    },
  ]

  return (
    <Row className="mb-3 g-3">
      {stats.map((stat, index) => (
        <Col md={3} sm={6} key={index}>
          <Card className="h-100 border-0 shadow-sm">
            <CardBody className="text-center">
              <h3 style={{ color: stat.color, fontWeight: 'bold' }}>
                {stat.value}
              </h3>
              <div className="text-muted small">{stat.title}</div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default GithubStats
