import React from 'react'
import { Modal, Button, Badge, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faBriefcase,
  faCalendarAlt,
  faCode,
} from '@fortawesome/free-solid-svg-icons'
import { JobExperience } from './type'
import styles from './ExperienceModal.module.css'

interface ExperienceModalProps {
  experience: JobExperience
  showModal: boolean
  handleClose: () => void
  logoUrl: string
  logoError: boolean
}

const ExperienceModal: React.FC<ExperienceModalProps> = ({
  experience,
  showModal,
  handleClose,
  logoUrl,
  logoError,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      centered
      dialogClassName={styles.modalDialog}
    >
      <Modal.Header closeButton className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          <div className="d-flex align-items-center mb-2">
            {!logoError ? (
              <Image
                src={logoUrl}
                className={styles.modalLogo}
                alt={experience.company}
              />
            ) : null}
            <h4 className={styles.modalJobTitle}>{experience.title}</h4>
          </div>
          <div className={styles.modalSubtitle}>
            <div>
              <FontAwesomeIcon
                icon={faBriefcase}
                className={styles.modalIcon}
              />
              <span>{experience.company}</span>
            </div>
            <div className="ms-3">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={styles.modalIcon}
              />
              <span>{experience.date}</span>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <p className={styles.jobDescription}>{experience.description}</p>

        <h5 className={styles.sectionTitle}>Key Achievements</h5>
        <div className={styles.achievementsList}>
          {experience.highlights.map((highlight, idx) => (
            <div key={idx} className={styles.achievementItem}>
              <div className={styles.checkCircle}>
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <div className={styles.achievementText}>{highlight}</div>
            </div>
          ))}
        </div>

        <h5 className={styles.sectionTitle}>
          <FontAwesomeIcon icon={faCode} className={styles.modalIcon} />
          Technologies
        </h5>

        <div className={styles.techTagsContainer}>
          {experience.hashtags.map((tag, idx) => (
            <Badge key={idx} className={styles.techTag}>
              {tag}
            </Badge>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button
          variant="secondary"
          onClick={handleClose}
          className={styles.closeButton}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ExperienceModal
