import React from 'react'
import { Col, Card, ListGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCalendarAlt,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons'

interface Article {
  title: string
  link: string
  pubDate: string
}

interface ArticlesSectionProps {
  articles: Article[]
  loading: boolean
}

const ArticlesSection: React.FC<ArticlesSectionProps> = ({
  articles,
  loading,
}) => {
  return (
    <Col lg={5} md={6} data-aos="fade-up" data-aos-delay="200">
      <h2 className="mb-4">Recent Articles</h2>
      {loading ? (
        <div className="text-center py-5">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2 text-muted">Loading articles...</p>
        </div>
      ) : articles.length > 0 ? (
        <Card className="shadow-sm">
          <ListGroup variant="flush">
            {articles.map((article, index) => (
              <ListGroup.Item
                key={index}
                className="py-3 px-4"
                action
                href={article.link}
                target="_blank"
              >
                <span className="text-muted small mb-1 d-block">
                  <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                  {article.pubDate}
                </span>
                <span className="fw-bold">{article.title}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Card.Footer className="bg-white text-center border-top-0">
            <Button
              variant="outline-success"
              href="https://blog.ardikapras.com"
              target="_blank"
              className="px-4"
            >
              View All Articles{' '}
              <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
            </Button>
          </Card.Footer>
        </Card>
      ) : (
        <Card className="text-center p-4 bg-light border-0 h-100">
          <Card.Body className="d-flex flex-column justify-content-center">
            <FontAwesomeIcon
              icon={faNewspaper}
              size="3x"
              className="text-secondary mb-3"
            />
            <Card.Title>No articles found</Card.Title>
            <Card.Text>
              Check out my blog directly for the latest content.
            </Card.Text>
            <Button
              variant="success"
              href="https://blog.ardikapras.com"
              target="_blank"
              className="mt-3"
            >
              Visit My Blog
            </Button>
          </Card.Body>
        </Card>
      )}
    </Col>
  )
}

export default ArticlesSection
