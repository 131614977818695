import React from 'react'
import { Badge } from 'react-bootstrap'
import styles from './CustomBadge.module.css'

interface CustomBadgeProps {
  text: string
  isSelectable?: boolean
  isSelected?: boolean
  onClick?: () => void
  variant?: 'skill' | 'tech' | 'soft' | 'filter'
  prefix?: string
  className?: string
}

const CustomBadge: React.FC<CustomBadgeProps> = ({
  text,
  isSelectable = false,
  isSelected = false,
  onClick,
  variant = 'skill',
  prefix,
  className = '',
}) => {
  const getVariantClass = () => {
    switch (variant) {
      case 'tech':
        return styles.techBadge
      case 'soft':
        return styles.softBadge
      case 'filter':
        return styles.filterBadge
      case 'skill':
      default:
        return styles.skillBadge
    }
  }

  const badgeClass = `
    ${getVariantClass()} 
    ${isSelectable ? styles.selectable : ''} 
    ${isSelected ? styles.selected : ''} 
    ${className}
  `

  return (
    <Badge
      bg={isSelected ? 'primary' : 'light'}
      text={isSelected ? 'white' : 'dark'}
      className={badgeClass}
      onClick={isSelectable ? onClick : undefined}
    >
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      {text}
    </Badge>
  )
}

export default CustomBadge
