import { Navbar, NavItem, NavLink } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faGitlab,
  faLinkedin,
  faMedium,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header
      id="header"
      className="p-sm-4 d-flex justify-content-between align-items-center sticky-top bg-dark text-light"
    >
      <h5 className="logo me-auto me-lg-0">
        <div className="text-light">Ardika Prasetyo</div>
      </h5>
      <Navbar className="pe-5 order-last order-lg-0">
        <NavItem className="me-3">
          <Link to="/" className="nav-link">
            /home
          </Link>
        </NavItem>
        <NavItem className="me-3">
          <Link to="/resume" className="nav-link">
            /resume
          </Link>
        </NavItem>
        <NavItem className="me-3">
          <Link
            to="https://blog.ardikapras.com"
            className="nav-link"
            target="_blank"
          >
            /blog
          </Link>
        </NavItem>
      </Navbar>
      <Navbar className="pe-5">
        <NavLink
          target="_blank"
          href="https://twitter.com/ardikapras"
          className="me-3"
        >
          <FontAwesomeIcon icon={faTwitter} title="Twitter" />
        </NavLink>
        <NavLink
          target="_blank"
          href="https://www.linkedin.com/in/ardikapras/"
          className="me-3"
        >
          <FontAwesomeIcon icon={faLinkedin} title="Linkedin" />
        </NavLink>
        <NavLink
          target="_blank"
          href="https://gitlab.com/ardikapras"
          className="me-3"
        >
          <FontAwesomeIcon icon={faGitlab} title="GitLab" />
        </NavLink>
        <NavLink
          target="_blank"
          href="https://github.com/ardikapras"
          className="me-3"
        >
          <FontAwesomeIcon icon={faGithub} title="GitHub" />
        </NavLink>
        <NavLink
          target="_blank"
          href="https://medium.com/@ardikapras"
          className="me-3"
        >
          <FontAwesomeIcon icon={faMedium} title="Medium" />
        </NavLink>
      </Navbar>
    </header>
  )
}

export default Header
