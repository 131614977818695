import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import AOS from 'aos'
import { GitHubActivity } from './github'
import { HeroSection, ProjectSection, ArticlesSection } from './sections'

interface Article {
  title: string
  link: string
  pubDate: string
}

const Home = () => {
  const [articles, setArticles] = useState<Article[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    })

    const fetchArticles = async () => {
      try {
        const rssFeedUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ardikapras'

        const response = await fetch(rssFeedUrl)

        const data = await response.json()

        if (data.status === 'ok') {
          setArticles(
            data.items.slice(0, 5).map((item: any) => ({
              title: item.title,
              link: item.link,
              pubDate: new Date(item.pubDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
            })),
          )
        }
      } catch (error) {
        console.error('Error fetching articles:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchArticles()
  }, [])

  return (
    <Container data-aos="fade-up" className="py-4">
      {/* Hero Section */}
      <HeroSection />

      {/* GitHub Contributions Section */}
      <section id="github" className="mb-5 py-3">
        <div data-aos="fade-up" data-aos-delay="200">
          <GitHubActivity />
        </div>
      </section>

      {/* Project and Articles Section */}
      <section id="work" className="py-3 mb-4">
        <Row>
          <ProjectSection />
          <ArticlesSection articles={articles} loading={loading} />
        </Row>
      </section>
    </Container>
  )
}

export default Home
