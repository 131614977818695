import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { ChartData } from './types'

interface GithubChartProps {
  monthlyData: ChartData[]
}

const GithubChart: React.FC<GithubChartProps> = ({ monthlyData }) => {
  return (
    <div className="mb-2 mt-4">
      <h6 className="text-muted mb-3">
        Contribution Activity (Last 12 Months)
      </h6>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={monthlyData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <XAxis dataKey="month" tick={{ fontSize: 12 }} tickMargin={10} />
          <YAxis tick={{ fontSize: 12 }} tickMargin={10} />
          <Tooltip
            contentStyle={{
              backgroundColor: 'white',
              borderRadius: '4px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
              border: 'none',
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="commits"
            name="Commits"
            stroke="#34b7a7"
            strokeWidth={3}
            dot={{ stroke: '#34b7a7', strokeWidth: 2, r: 4 }}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default GithubChart
