import React from 'react'
import { Row, Col, Button, Image } from 'react-bootstrap'
import { TypeAnimation } from 'react-type-animation'

const HeroSection: React.FC = () => {
  return (
    <section id="hero" className="mb-5 pt-4">
      <Row className="align-items-center">
        <Col lg={8} md={7} sm={12} className="text-lg-start text-center">
          <h1 className="display-4 fw-bold mb-3" data-aos="fade-up">
            Hi! I'm Ardika Prasetyo
          </h1>
          <div className="mb-4" data-aos="fade-up" data-aos-delay="200">
            <TypeAnimation
              sequence={[
                `Software Engineer`,
                2000,
                `Back-End Engineer`,
                2000,
                `Full-stack Engineer`,
                2000,
              ]}
              speed={50}
              repeat={Infinity}
              className="fs-2 text-success"
            />
          </div>
          <p className="lead mb-4" data-aos="fade-up" data-aos-delay="400">
            Passionate about building robust, scalable software solutions with
            extensive experience in banking, fintech, and enterprise systems. I
            thrive on transforming complex requirements into elegant technical
            implementations.
          </p>
          <div
            className="d-flex gap-3 justify-content-lg-start justify-content-center"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <Button variant="success" size="lg" href="/resume">
              View Resume
            </Button>
            <Button
              variant="outline-dark"
              size="lg"
              href="https://blog.ardikapras.com"
              target="_blank"
            >
              Read My Blog
            </Button>
          </div>
        </Col>
        <Col
          lg={4}
          md={5}
          sm={12}
          className="text-center"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div className="position-relative">
            <div
              className="position-absolute top-0 start-0 translate-middle bg-success rounded-circle"
              style={{
                width: '80px',
                height: '80px',
                opacity: 0.3,
                zIndex: -1,
              }}
            ></div>
            <div
              className="position-absolute bottom-0 end-0 translate-middle bg-success rounded-circle"
              style={{
                width: '120px',
                height: '120px',
                opacity: 0.3,
                zIndex: -1,
              }}
            ></div>
            <Image
              src="profpict.jpg"
              className="img-fluid rounded-circle border border-5 border-light shadow"
              style={{ maxWidth: '300px' }}
            />
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default HeroSection
