import React from 'react'
import { Card, ListGroup, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faLaptopCode,
  faCode,
} from '@fortawesome/free-solid-svg-icons'
import { CustomBadge } from '../common'
import styles from './BackgroundInfo.module.css'

const BackgroundInfo: React.FC = () => {
  const domains = [
    { name: 'Banking', icon: faBriefcase },
    { name: 'Fin-Tech', icon: faLaptopCode },
    { name: 'Research & Development', icon: faCode },
    { name: 'Loan Origination System', icon: faBriefcase },
  ]

  const softSkills = [
    'Good in adaptability',
    'Fast learner',
    'Team player',
    'Project Planning',
    'Self management',
    'Works under pressure',
  ]

  return (
    <Card className={styles.summaryCard}>
      <Card.Header className={styles.cardHeader}>
        <h4 className="mb-0">
          <FontAwesomeIcon icon={faBriefcase} className={styles.icon} />
          Background
        </h4>
      </Card.Header>
      <Card.Body className="p-0">
        {/* Domains List */}
        <div className={styles.sectionDivider}>
          <div className={styles.dividerTitle}>Domains</div>
        </div>
        <ListGroup variant="flush">
          {domains.map((domain, index) => (
            <ListGroup.Item key={index} className={styles.listItem}>
              <FontAwesomeIcon
                icon={domain.icon}
                className={styles.listItemIcon}
              />
              {domain.name}
            </ListGroup.Item>
          ))}
        </ListGroup>

        {/* Soft Skills List */}
        <div className={styles.sectionDivider}>
          <div className={styles.dividerTitle}>Soft Skills</div>
        </div>
        <div className={styles.softSkillsContainer}>
          {softSkills.map((skill, index) => (
            <CustomBadge key={index} text={skill} variant="soft" />
          ))}
        </div>

        {/* Education */}
        <div className={styles.sectionDivider}>
          <div className={styles.dividerTitle}>Education</div>
        </div>
        <div className={styles.educationSection}>
          <h5>Bachelor of Computer Science</h5>
          <div className="d-flex align-items-center mb-2">
            <Badge className={styles.educationBadge}>2006 - 2010</Badge>
            <span className="ms-2 text-muted">
              Indonesia University of Education
            </span>
          </div>
          <a
            href="http://repository.upi.edu/2942/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.educationLink}
          >
            Implementasi teknik gamifikasi pada sistem e-learning
          </a>
        </div>
      </Card.Body>
    </Card>
  )
}

export default BackgroundInfo
