import React from 'react'
import { Card, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { CustomBadge } from '../common'
import styles from './SkillsFilter.module.css'

interface SkillsFilterProps {
  selectedHashtags: string[]
  toggleHashtag: (hashtag: string) => void
  clearSelection: () => void
}

const SkillsFilter: React.FC<SkillsFilterProps> = ({
  selectedHashtags,
  toggleHashtag,
  clearSelection,
}) => {
  const skillCategories = {
    languages: ['java', 'golang', 'typescript', 'kotlin'],
    frontend: ['react', 'vaadin', 'flutter'],
    backend: ['spring-boot', 'microservices', 'oracle-weblogic', 'coldfusion'],
    database: [
      'postgresql',
      'sql-server',
      'oracle-db',
      'redis',
      'mongodb',
      'elasticsearch',
    ],
    cloud: ['aws'],
    tools: ['selenium', 'message-queue', 'jest'],
    domain: ['banking', 'loan-origination-system', 'tech-lead'],
  }

  const isAnyInCategorySelected = (category: string[]) => {
    return category.some((tag) => selectedHashtags.includes(tag))
  }

  return (
    <Card className={styles.summaryCard}>
      <Card.Header className={styles.cardHeader}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">
            <FontAwesomeIcon icon={faCode} className={styles.icon} />
            Skills Filter
          </h4>
          {selectedHashtags.length > 0 && (
            <Badge
              bg="secondary"
              className={styles.clearButton}
              onClick={clearSelection}
            >
              Clear All
            </Badge>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {/* Languages */}
        <SkillCategorySection
          title="Languages"
          skills={skillCategories.languages}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected(skillCategories.languages)}
        />

        {/* Frontend */}
        <SkillCategorySection
          title="Frontend"
          skills={skillCategories.frontend}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected(skillCategories.frontend)}
        />

        {/* Backend */}
        <SkillCategorySection
          title="Backend"
          skills={skillCategories.backend}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected(skillCategories.backend)}
        />

        {/* Database */}
        <SkillCategorySection
          title="Database"
          skills={skillCategories.database}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected(skillCategories.database)}
        />

        {/* Cloud */}
        <SkillCategorySection
          title="Cloud & DevOps"
          skills={skillCategories.cloud}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected(skillCategories.cloud)}
        />

        {/* Tools & Domain */}
        <SkillCategorySection
          title="Tools & Specialties"
          skills={[...skillCategories.tools, ...skillCategories.domain]}
          selectedHashtags={selectedHashtags}
          toggleHashtag={toggleHashtag}
          isActive={isAnyInCategorySelected([
            ...skillCategories.tools,
            ...skillCategories.domain,
          ])}
        />
      </Card.Body>
    </Card>
  )
}

interface SkillCategorySectionProps {
  title: string
  skills: string[]
  selectedHashtags: string[]
  toggleHashtag: (hashtag: string) => void
  isActive: boolean
}

const SkillCategorySection: React.FC<SkillCategorySectionProps> = ({
  title,
  skills,
  selectedHashtags,
  toggleHashtag,
  isActive,
}) => {
  return (
    <div
      className={`${styles.tagSection} ${isActive ? styles.activeSection : ''}`}
    >
      <div className={styles.tagSectionHeader}>
        <span>{title}</span>
      </div>
      <div className={styles.tagContainer}>
        {skills.map((skill) => (
          <CustomBadge
            key={skill}
            text={skill}
            isSelectable
            isSelected={selectedHashtags.includes(skill)}
            onClick={() => toggleHashtag(skill)}
          />
        ))}
      </div>
    </div>
  )
}

export default SkillsFilter
