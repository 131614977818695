import { Col, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import styles from '../styles.module.css'
import { JobExperience, ExperienceCard } from '../resume'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

type TechJourneyProps = {
  jobExperiences: JobExperience[]
}

const TechJourney: React.FC<TechJourneyProps> = ({ jobExperiences }) => {
  const [displayedExperiences, setDisplayedExperiences] = useState<
    JobExperience[]
  >([])

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    setDisplayedExperiences(jobExperiences)
  }, [jobExperiences])

  return (
    <div>
      <h3 className={`${styles['resume-title']}`}>Tech Journey</h3>

      <TransitionGroup component={Row} xs={1} md={2} className="g-4">
        {displayedExperiences.map((experience) => (
          <CSSTransition
            key={experience.id}
            timeout={500}
            classNames={{
              enter: styles.cardEnter,
              enterActive: styles.cardEnterActive,
              exit: styles.cardExit,
              exitActive: styles.cardExitActive,
            }}
          >
            <Col>
              <ExperienceCard experience={experience} />
            </Col>
          </CSSTransition>
        ))}
      </TransitionGroup>

      {displayedExperiences.length === 0 && (
        <div className={styles.noResults}>
          <p>No experiences match the selected filters.</p>
          <p>Try selecting different skills or clearing all filters.</p>
        </div>
      )}
    </div>
  )
}

export default TechJourney
