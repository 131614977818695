import React from 'react'
import styles from './SectionTitle.module.css'

interface SectionTitleProps {
  title: string
  className?: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  className = '',
}) => {
  return <h3 className={`${styles.sectionTitle} ${className}`}>{title}</h3>
}

export default SectionTitle
