import React from 'react'
import { Col, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { CustomBadge } from '../common'

const ProjectSection: React.FC = () => {
  const project = {
    id: 1,
    title: 'Content Aggregator',
    description:
      'A modern news aggregation platform that automatically collects, processes, and displays articles from 30+ online sources using RSS feeds. Built with Spring Boot, Kotlin, and React.',
    technologies: [
      'Kotlin',
      'Spring-Boot',
      'PostgreSQL',
      'ReactJS',
      'Typescript',
      'kafka',
    ],
    image: '1_dashboard.png',
  }

  return (
    <Col lg={7} md={6} className="mb-4 mb-md-0" data-aos="fade-up">
      <h2 className="mb-4">Personal Project</h2>
      <Card className="shadow-sm">
        {project.image && (
          <div className="p-0">
            <div
              style={{
                paddingTop: '56.25%', // 16:9 aspect ratio
                width: '100%',
                backgroundImage: `url(/projects/${project.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        )}
        <Card.Body>
          <Card.Title className="fw-bold text-success fs-4 mb-3">
            {project.title}
          </Card.Title>
          <Card.Text className="mb-3">{project.description}</Card.Text>
          <div>
            {project.technologies.map((tech, index) => (
              <CustomBadge
                key={index}
                text={tech}
                variant="tech"
                className="me-2 mb-2 p-2"
              />
            ))}
          </div>
        </Card.Body>
        <Card.Footer className="bg-white border-top-0 pb-3">
          <Button
            variant="success"
            className="w-100 py-2"
            href="https://github.com/ardikapras/content-aggregator"
            target="_blank"
          >
            View on GitHub{' '}
            <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  )
}

export default ProjectSection
